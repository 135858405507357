
#sites-footer {
	text-align: center;
	margin: @sites-footer-margin;
	font-size: @sites-footer-size;
	color: @sites-footer-color;
	.traffic-trade {
		font-size: @sites-footer-traffictrade-size;
		margin: @sites-footer-traffictrade-margin;
	}
}