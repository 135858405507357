
@import "../../bootstrap/variables.less";

@body-bg:					#fff;

@text-color:				#000;
@link-color:				#d50000;
@link-hover-color:			#d50000;
@link-hover-decoration:		underline;

@font-family-base:			Verdana, Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1.2;
@font-size-h1:				56px;
@headings-small-color:		#000;

@pager-color:					#d50000;
@pager-color-active:			#d50000;
@pager-padding:					2px;
@pager-border:					none;
@pager-text-decoration:			underline;
@pager-font-weight-active:		normal;
@pager-text-decoration-active:	none;
@pager-hover-bg:				transparent;
@pager-font-size:				24px;
@pager-font-size-mobile:		24px;

@thumb-width:				490px;
@thumb-height:				368px;
@thumb-margin-top:			0;
@thumb-margin-right:		1px;
@thumb-margin-bottom:		0;
@thumb-margin-left:			1px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #000;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#000;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:				5px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0 0 10px;
@sites-footer-margin:				2px;
@sites-footer-size:					12px;
@sites-footer-color:				@text-color;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";
@import "common/pagination.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	text-align: center;
	margin: 0 auto 5px;
	padding: 2px;
	max-width: 920px;
	.text-nowrap {
		float: left;
	}
	small {
		font-size: 18px;
		line-height: 20px;
		display: block;
		overflow: hidden;
		text-align: left;
		padding-left: 30px;
	}
}
.red {
	color: #f00;
}
.links {
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	a.nolink {
		text-decoration: none;
		color: #000;
	}
}
.thumb-simple {
	border-bottom: 1px solid #FB0000;
	height: @thumb-height + @thumb-margin-top + @thumb-margin-bottom + (2 * @thumb-border-width) + 1;
}
@media(max-width: 768px) {
	h1 {
		.text-nowrap {
			float: none;
		}
		small {
			padding: 5px;
		}
	}
}
