
#sites-list {
	max-width: 1100px;
	margin: 0 auto;
	font-size: @sites-list-font-size;
	color: @sites-list-color;
	h2 {
		text-align: center;
		margin-bottom: @sites-list-title-margin-bottom;
		font-size: @sites-list-title-size;
		font-weight: @sites-list-title-weight;
		text-decoration: @sites-list-title-decoration;
	}
	ol {
		.inline-block;
		width: 24%;
		margin: 0;
		padding: 0 0 0 0.5%;
		li {
			text-align: left;
			list-style-position: inside;
		}
	}
}

@media(max-width: 768px) {
	#sites-list {
		ol {
			width: 49%;
		}
	}
}
@media(max-width: 500px) {
	#sites-list {
		ol {
			width: 99%;
		}
	}
}