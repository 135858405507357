
.pagination {
    margin: 6px 0;
    overflow: hidden;
	clear: left;
	white-space: nowrap;
    ul {
		margin: 0;
        padding: 0 0 0 1px;
        vertical-align: middle;
        li {
            .inline-block;
            padding: 0;
			margin: 0 2px 0 0;
            a {
                .inline-block;
                padding: @pager-padding;
				margin-left: -1px;
				margin-bottom: 2px;
                border: @pager-border;
                text-decoration: @pager-text-decoration;
				color:  @pager-color;
				font-size: @pager-font-size;
				&.no-page,
				&.active {
					color:  @pager-color-active;
					font-weight: @pager-font-weight-active;
					border-color: transparent;
					text-decoration: @pager-text-decoration-active;
				}
				&:hover {
					background-color: @pager-hover-bg;
					border-color: #888;
					&.no-page,
					&.active {
						background-color: transparent;
						border-color: transparent;
					}
				}
			}
			&:last-child {
				margin-right: 0;
			}
        }
    }
	&.center {
		ul {
			text-align: center;
		}
	}
	@media(max-width: @screen-sm) {
		ul {
			li a {
				font-size: @pager-font-size-mobile;
				padding: 0 8px;
			}
		}
		&.center {
			ul {
				text-align: left;
			}
		}
	}
}
